<template>
  <div>

    <button @click="showPicker">点击查看</button>
    <Calendar :datePickerShow="datePickerShow" :feeProps="feeProps" @feeClose="closeFee"></Calendar>

  </div>
</template>
<script>
import Calendar from './calendar.vue'
export default {
  components: {
    Calendar
  },
  // ...
  data () {
    return {
      datePickerShow: false,
      feeProps: {}
    }
  },
  methods: {
    closeFee () {
      this.datePickerShow = false
    },
    getFeeProps () {
      // this.feeProps = {xxx}
    },
    showPicker () {
      this.feeProps = {
        '2020-03-30': 11,
        '2020-04-01': 11,
        '2020-04-02': 11
      }
      this.datePickerShow = true
    }
  }
}
</script>
<style lang="">

</style>
